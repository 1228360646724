import * as React from 'react';
import { useState, useEffect } from 'react';

import * as styles from './LoadingIndicator.module.scss';

export interface ILoadingIndicatorProps{
    fontSize?: string;
}

export const LoadingIndicator = (props: ILoadingIndicatorProps) => {
    const { fontSize } = props;
     return (
        <div className={ styles.container }>
            <div className={ styles.indicatorContainer } style={{ fontSize: fontSize ? fontSize : "" }}>
                <div className={ styles.leftBar }></div>
                <div className={ styles.middleBar }></div>
                <div className={ styles.rightBar }></div>
            </div>
        </div>
    )
}